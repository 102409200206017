'use strict'

const service = config.VUE_APP_service_licenze || ''

async function getTabella(tabellaId) {
  const response = await fetch(`${service}/tabelle/${tabellaId}`)
  return await response.json()
}

async function modificaTabella(tabella) {
  const response = await fetch(`${service}/tabelle/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tabella)
  })
  return await response.json()
}

export default {
  getTabella,
  modificaTabella
}