<template>
  <v-row>
    <v-col>
      <v-card flat :loading="loading">
        <v-card-title>
          Confronta il contenuto delle impostazioni di due clienti
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <!-- <v-text-field v-model="sorgente" dense outlined label="Cliente sorgente (base db)" clearable @keydown.enter="onFiltra" hide-details @click:clear="onClear" @change="onChangeDb"></v-text-field> -->
              <v-autocomplete v-model="sorgente" :items="clientiSorted" dense flat outlined label="Cliente sorgente, il più completo (base db)" item-text="descrizione" item-value="codice" clearable hide-details return-object @change="onChangeDb" @click:clear="onClearSorgente">
                <template v-slot:[`item`]="{ item }">
                  <span class="v-list-item__title">{{ item.descrizione + ' : ' + item.codice }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="destinazione" :items="clientiSorted" dense flat outlined label="Cliente destinazione (base db)" item-text="descrizione" item-value="codice" clearable hide-details return-object @change="onChangeDb" @click:clear="onClearDestinazione">
                <template v-slot:[`item`]="{ item }">
                  <span class="v-list-item__title">{{ item.descrizione + ' : ' + item.codice }}</span>
                </template>                
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete v-model="nomeDocumento" :items="itemsSorgente" dense flat outlined label="Configurazione (prende le tabelle dal db sorgente)" item-text="descrizione" item-value="codice" clearable hide-details @change="onChangeDocumento" @click:clear="onClearConfigurazione"></v-autocomplete>
            </v-col>
            <!-- <v-col cols="3"><v-switch v-model="visualizzaDifferenze" class="mt-1" inset @change="onChangeVisualizzaDifferenze" label="Visualizza differenze" hint="Abilitare manualmente la visualizzazione" :persistent-hint="!visualizzaDifferenze"></v-switch></v-col> -->
          </v-row>    
          <v-row>
            <v-col cols="12" lg="4">
              <span class="text-h5">Parametri sorgente</span>
              <v-json-editor v-model="jsonWebS" :options="optionsWeb" :plus="true" history @error="onErrorWeb" style="height: 100%;" ref="jsonEditorS"/>
            </v-col>
            <v-col cols="12" lg="4">
             <span class="text-h5">Parametri destinazione</span>
              <v-json-editor v-model="jsonWebD" :options="optionsWeb" :plus="true" history @error="onErrorWeb" style="height: 100%;" ref="jsonEditorD"/>
            </v-col>
            <v-col cols="12" lg="4">
              <v-row>
                <v-col>
                  <span class="text-h5">Differenze<v-btn icon @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn></span>
                </v-col>
                <v-col class="pt-0 mb-3">
                  <v-radio-group v-model="diff_type" row @change="onChangeType">
                    <v-radio label="Chars" value="diffChars" :disabled="!visualizzaDifferenze"></v-radio>
                    <v-radio label="Words" value="diffWords" :disabled="!visualizzaDifferenze"></v-radio>
                    <v-radio label="Lines" value="diffLines"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <pre id="result"></pre>         
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row class="d-flex">
            <v-col></v-col>
            <v-col>
              <v-btn color="success" @click="onSalvaJson">Salva</v-btn>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions></v-card-actions> 
      </v-card>
    </v-col>
    <dialog-conferma
        :show="dialogConferma"
        :title="titoloConferma"
        :message="messaggioConferma"
        :onlyOk="onlyOk"
        :confirmText="confirmText"
        @dialog-confirm="onConferma"
        @dialog-cancel="operazioneEseguitaConfirm"
      />
  </v-row>
</template>

<script>
/*   import utility from '@/services/utilityService'
  import FileList from './fileList' */
  import _ from 'lodash'

  import VJsonEditor from 'v-jsoneditor'
  import DialogConferma from '@/components/dialogConferma'
  import configurazioni from '@/services/configurazioniRemoteService'
  import clienti from '@/services/clientiService.js'
  // require('colors');
  const Diff = require('diff');

  export default {
    components: {
      VJsonEditor,
      DialogConferma
    },
    data (vm) {
      return {
        loading: false,
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.operazioneEseguitaConfirm,
        confirmText: 'SI',
        sorgente: '',
        destinazione: '',
        elencoConfigurazioniSorgente: [],
        elencoConfigurazioniDestinazione: [],
        itemsSorgente: [],
        itemsDestinazione: [],
        nomeDocumento: '',
        jsonMode: 'code',
        optionsWeb: {
          mode: 'code',
          modes: ['view', 'form', 'code'],
          search: true,
          enableSort: false,
          enableTransform: true
        },
        jsonWebS: '',
        jsonWebD: '',
        elencoClienti: [],
        differenze: '',
        diff_type: 'diffChars',
        refreshTick: 1,
        dbNameD: '',
        visualizzaDifferenze: true
      }
    },
    async mounted() {
      const clientiRaw = await clienti.getClienti()
      this.elencoClienti = clientiRaw.map(x => { return { codice: x.licenze[0].baseDb, descrizione: x.ragioneSociale }})
    },
    computed: {
      clientiSorted() {
        const els = this.elencoClienti
        const elSorted = _.sortBy(els, ['descrizione'])
        return elSorted
      }
    },
    methods: {
      async onChangeDb() {
        if (!this.sorgente || !this.destinazione) return
        let dbName = this.sorgente.codice + '_configurazioni'
        let elenco = await configurazioni.getElencoConfigurazioni(dbName, true)
        if (elenco && elenco.length > 0) {
          this.elencoConfigurazioniSorgente = elenco.map(x => x.doc)
          this.itemsSorgente = elenco.map(x => x.id).sort()
        }
        dbName = this.destinazione.codice + '_configurazioni'
        this.dbNameD = dbName
        elenco = await configurazioni.getElencoConfigurazioni(dbName, true)
        if (elenco && elenco.length > 0) {
          this.elencoConfigurazioniDestinazione = elenco.map(x => x.doc)
          this.itemsDestinazione = elenco.map(x => x.id).sort()
        }
        this.onChangeDocumento(this.nomeDocumento)
      },
      onChangeType() {
        this.refresh()
      },
      onChangeDocumento(codice) {
        const datiSorgente = this.elencoConfigurazioniSorgente.find(x => x._id === codice)
        const datiDestinazione = this.elencoConfigurazioniDestinazione.find(x => x._id === codice)
        this.jsonWebS = datiSorgente || {}
        this.jsonWebD = datiDestinazione || {}
        if (datiSorgente) {
          this.visualizzaDifferenze = JSON.stringify(this.jsonWebS, null, 4).length < 5000
          if (!this.visualizzaDifferenze) {
            this.diff_type="diffLines"
          }
          this.refresh()
        }
      },
      onChangeVisualizzaDifferenze(value) {
        if (value) {
          this.refresh()
        }
      },
      refresh() {
        const ds = JSON.stringify(this.jsonWebS, null, 4)
        const dd = JSON.stringify(this.jsonWebD, null, 4)
        const diff = Diff[this.diff_type](ds, dd)
        var fragment = document.createDocumentFragment()
        for (var i=0; i < diff.length; i++) {

          if (diff[i].added && diff[i + 1] && diff[i + 1].removed) {
            var swap = diff[i]
            diff[i] = diff[i + 1]
            diff[i + 1] = swap
          }
          var node
          if (diff[i].removed) {
            node = document.createElement('del')
            node.appendChild(document.createTextNode(diff[i].value))
          } else if (diff[i].added) {
            node = document.createElement('ins')
            node.appendChild(document.createTextNode(diff[i].value))
          } else {
            node = document.createTextNode(diff[i].value)
          }
          fragment.appendChild(node)
        }
        var result = document.getElementById('result')
        result.textContent = ''
        this.differenze = fragment
        result.appendChild(fragment)
      },
      onSalvaJson() {
        this.titoloConferma = 'Salvataggio modifiche'
        this.messaggioConferma = `Confermi salvataggio modifiche ?`
        this.onConferma = this.salvaJson
        this.dialogConferma = true
      },
      async salvaJson() {
        const dati = this.jsonWebD
        await configurazioni.modificaConfigurazione(this.dbNameD, dati)
        this.dialogConferma = false
        this.refreshTick++
        await this.onChangeDb()
        this.operazioneEseguitaConfirm()
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.confirmText = 'SI'
        this.onlyOk = false
      },
      clearDifferenze() {
        var result = document.getElementById('result')
        result.textContent = ''
      },
      onClearSorgente() {
        this.jsonWebS = ''
        this.clearDifferenze()
      },
      onClearDestinazione() {
        this.jsonWebD = ''
        this.clearDifferenze()
      },
      onClearConfigurazione() {
        this.jsonWebS = ''
        this.jsonWebD = ''
        this.clearDifferenze()
      },
      onErrorWeb() {}
    }
  }
</script>

<style>
  pre {
    font-size: 13px;
    line-height: 17px;;
  }
  del {
    text-decoration: none;
    color: #b30000;
    background: #fadad7;
  }
  ins {
    background: #eaf2c2;
    color: #406619;
    text-decoration: none;
  }

</style>