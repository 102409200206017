<template>
 <div>
   <v-row justify="space-around" class="mt-3">
      <v-col md="6" sm="12" class="text-h5">Configurazioni</v-col>
      <v-col md="6" sm="12" lg="5" class="text-h5">Gruppi
          <v-btn color="success" fab x-small @click="onAddGruppo">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
      </v-col>
    </v-row>
    <v-row justify="space-around" flex class="mb-5">
      <v-col md="6" sm="12">
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="item in snipletsGruppi" :key="item.codice">
            <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="3">{{item.descrizione}}</v-col>
                <v-col cols="8" class="text--secondary">{{item.note}}</v-col>
                <v-col cols="1" v-if="!open" title="Esistenti (di cui Default)">{{totaleSelezionati(item)}}</v-col>        
              </v-row>
            </template>          
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="start" no-gutters>
                <v-col >
                  <v-list flat>
                    <v-list-item-group v-model="settings" multiple active-class="">
                      <v-list-item v-for="snip in snipletsEsistentiGruppo(item.codice)" :key="snip.codice" dense :three-line="snip.note && snip.note.length > 60" class="mb-2">
                        <!-- <template v-slot:default="{ active }"> -->
                        <template v-slot:default>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                              <!-- <template v-slot:default="{ open }"> -->
                              <template v-slot:default>
                                <v-list-item-avatar max-width="50">
                                  <v-icon v-if="snip.ordine !== 0" @click.stop="moveUp(snip)" small title="Sposta il gruppo in alto">mdi-arrow-up-bold-outline</v-icon>
                                  <v-icon v-if="snip.ordine !== numSnipGruppo(snip)-1" @click.stop="moveDown(snip)" small title="Sposta il gruppo in basso">mdi-arrow-down-bold-outline</v-icon>
                                </v-list-item-avatar>                                     
<!--                                 <v-list-item-icon>
                                  <v-icon @click.stop="onDeleteConfig(snip)" title="Elimina l'impostazione">mdi-delete-outline</v-icon>
                                </v-list-item-icon>        -->                         
                                <v-list-item-content>
                                  <v-list-item-title>{{snip.titolo}}</v-list-item-title>
                                  <v-list-item-subtitle>{{snip.note}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-avatar max-width="50">
                                  <v-icon  class="mr-2" @click.stop="onDeleteConfig(snip)" title="Elimina l'impostazione">mdi-delete-outline</v-icon>
                                  <v-icon @click.stop="onEditItem(snip)" :disabled="!snip.editable" title="Modifica i parametri">mdi-pencil</v-icon>
                                </v-list-item-avatar>
                              </template>          
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row justify="start" fluid>
                                  <v-col><v-select label="Categoria" v-model="snip.categoria" :disabled="!snip.editable" :items="snipletsCategorie" dense></v-select></v-col>
                                  <v-col>
                                    <v-select
                                      label="Gruppo"
                                      v-model="snip.gruppo"
                                      :disabled="!snip.editable"
                                      :items="snipletsGruppi"
                                      item-value="codice"
                                      item-text="descrizione"
                                      dense
                                    />
                                  </v-col>
                                  <v-col><v-switch v-model="snip.default" :disabled="!snip.editable" dense inset label="Default" title="Il parametro è aggiunto di default alle impostazioni del cliente"></v-switch></v-col>
                                  <v-col><v-switch v-model="snip.daPersonalizzare" :disabled="!snip.editable" dense inset label="Modificabile" title="Il contenuto può essere modificato in fase di inserimento"></v-switch></v-col>       
                                </v-row>
                                <v-row justify="start">
                                  <v-col><v-text-field v-model="snip.titolo" :disabled="!snip.editable" label="Titolo" placeholder="Titolo" dense></v-text-field></v-col>
                                </v-row>
                                <v-row justify="start">
                                  <v-col><v-text-field v-model="snip.note" :disabled="!snip.editable" label="Note" placeholder="Descrizione del valore" dense></v-text-field></v-col>
                                </v-row>
                                <v-row justify="start">
                                  <v-col><v-text-field v-model="snip.posizione" :disabled="!snip.editable" label="Posizione" placeholder="Database.Impostazione / Database" dense></v-text-field></v-col>
                                </v-row>
                                <v-row justify="start">
                                  <v-col>
                                    <v-btn color="primary" v-if="!snip.editable" dark @click="onModifica(snip)">Modifica</v-btn>
                                    <v-btn color="warning" v-if="snip.editable" class="mr-3" dark @click="onAnnulla(snip)">Annulla</v-btn>
                                    <v-btn color="success" v-if="snip.editable" dark @click="onSalva(snip)">Salva</v-btn>
                                  </v-col>
                                </v-row>  
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                    <v-fab-transition>
                      <v-btn color="success" fab dark small absolute bottom right @click="onAddConfig(item.codice)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </v-list>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col md="6" sm="12" lg="5">
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="item in snipletsGruppi" :key="item.codice">
            <v-expansion-panel-header>
              <template v-slot:default>
<!--                 <v-list-item-avatar max-width="20" dense>
                  <v-icon v-if="item.ordine !== 0" @click.stop="moveUp(item)" small title="Sposta il gruppo in alto">mdi-arrow-up-bold-outline</v-icon>
                  <v-icon v-if="item.ordine !== numGruppi-1" @click.stop="moveDown(item)" small title="Sposta il gruppo in basso">mdi-arrow-down-bold-outline</v-icon>
                </v-list-item-avatar>    -->             
                <v-row no-gutters>
                  <v-col cols="3">{{item.descrizione}}</v-col>
                  <v-col cols="8" class="text--secondary">{{item.note}}</v-col>   
                </v-row>
                <v-list-item-avatar max-width="30" dense>
                  <v-icon @click.stop="onDeleteGruppo(item)" :disabled="snipletsEsistentiGruppo(item.codice).length > 0" dense title="Elimina gruppo">mdi-delete-outline</v-icon>
                </v-list-item-avatar>                  
              </template>
<!--             <template v-slot:default>
              <v-list-item-avatar max-width="50">
                <v-icon v-if="snip.ordine !== 0" @click.stop="moveUp(snip)" small title="Sposta il gruppo in alto">mdi-arrow-up-bold-outline</v-icon>
                <v-icon v-if="snip.ordine !== numSnipGruppo(snip)-1" @click.stop="moveDown(snip)" small title="Sposta il gruppo in basso">mdi-arrow-down-bold-outline</v-icon>
              </v-list-item-avatar>                                                           
              <v-list-item-content>
                <v-list-item-title>{{snip.titolo}}</v-list-item-title>
                <v-list-item-subtitle>{{snip.note}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar max-width="50">
                <v-icon  class="mr-2" @click.stop="onDeleteConfig(snip)" title="Elimina l'impostazione">mdi-delete-outline</v-icon>
                <v-icon @click.stop="onEditItem(snip)" :disabled="!snip.editable" title="Modifica i parametri">mdi-pencil</v-icon>
              </v-list-item-avatar>
            </template>   -->        
            </v-expansion-panel-header>
            <v-expansion-panel-content>
<!--               <v-row justify="start">
                <v-col><v-text-field v-model="item.codice" readonly :disabled="!item.editable" label="Codice" placeholder="Codice" dense></v-text-field></v-col>
              </v-row> -->
              <v-row justify="start">
                <v-col><v-text-field v-model="item.descrizione" :disabled="!item.editable" label="Descrizione" placeholder="Descrizione" dense @change="onChangeDescrizioneGruppo(item)"></v-text-field></v-col>
              </v-row>                                
              <v-row justify="start">
                <v-col><v-text-field v-model="item.note" :disabled="!item.editable" label="Note" placeholder="Note" dense></v-text-field></v-col>
              </v-row>
              <v-row justify="start">
                <v-col>
                  <v-btn color="primary" v-if="!item.editable" dark @click="onModificaGruppo(item)">Modifica</v-btn>
                  <v-btn color="warning" v-if="item.editable" class="mr-3" dark @click="onAnnullaGruppo(item)">Annulla</v-btn>
                  <v-btn color="success" v-if="item.editable" dark @click="onSalvaGruppo(item)">Salva</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>  
      <dialog-conferma
        :show="dialogConferma"
        :title="titoloConferma"
        :message="messaggioConferma"
        :onlyOk="onlyOk"
        :confirmText="confirmText"
        @dialog-confirm="onConferma"
        @dialog-cancel="operazioneEseguitaConfirm"
      />

      <v-dialog v-model="dialogModifica" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Modifica avanzata parametri</span>
          </v-card-title>
          <v-card-text>
            <v-json-editor v-model="jsonWeb" :options="optionsWeb" :plus="true" history @error="onErrorWeb" style="height: 100%;" ref="jsonEditor"/>
            <small>Per ricaricare i valori di default dell'impostazione (se modificata per il cliente) è sufficiente rimuovere il parametro e ricaricare la lista</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onEditorChiudi">Chiudi</v-btn>
            <v-btn color="blue darken-1" text @click="onEditorSalva">Salva</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
  import _ from 'lodash'
  import VJsonEditor from 'v-jsoneditor'
  import { v4 as uuidv4 } from 'uuid'
  import sniplets from '@/services/snipletsService'
  import configurazioni from '@/services/configurazioniRemoteService'
  import DialogConferma from '@/components/dialogConferma'

  export default {
    components: {
      VJsonEditor,
      DialogConferma
    },    
    data (vm) {
      return {
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.operazioneEseguitaConfirm,
        confirmText: 'SI',
        snipletsCategorie: ['Impostazioni', 'ValoriDB'], 
        snipletsGruppi: [],
        snipletsEsistenti: [],
        snipletsEsistentiOrdinati: [],
        settings: [],
        editedItem: null,
        undoSnip: null,
        undoGruppo: null,
        dialogModifica: false,
        jsonWeb: '',
        jsonMode: 'code',        
        optionsWeb: {
          mode: 'code',
          modes: ['code', 'form'],
          search: false,
          enableSort: false,
          enableTransform: false
        },
        toDelete: null,
        toDeleteGruppo: null
      }
    },
    async mounted() {
      await this.leggeSnipletsEsistenti()
    },
    computed: {
    },
    methods: {
      onErrorWeb() {
        console.log('errore da gestire')
      },
      onEditItem(snip) {
        this.editedItem = snip
        this.jsonWeb = snip.payload
        // const jEd = this.$refs.jsonEditor
        // jEd.expandAll()
        this.dialogModifica = true
      },
      onEditorChiudi() {
        this.editedItem = null
        this.jsonWeb = ''
        this.dialogModifica = false
      },
      onEditorSalva() {
        if (!this.editedItem.payload || JSON.stringify(this.editedItem.payload) !== JSON.stringify(this.jsonWeb)) {
          this.editedItem.payload = this.jsonWeb
          this.editedItem.personalizzato = true
        } else {
          this.editedItem.personalizzato = false
        }
        this.editedItem.modified = true
        this.editedItem = null
        this.jsonWeb = ''
        this.dialogModifica = false
      },
      onModifica(snip) {
        this.undoSnip = { ...snip }
        snip.editable = true
      },
      async onAnnulla(snip) {
        if (this.undoSnip) {
          let snipE = this.snipletsEsistenti.find(x => x.codice === this.undoSnip.codice)
          Object.assign(snipE, { ...this.undoSnip})
          this.undoSnip = null
        }
        delete snip.editable
      },
      async onSalva(snip) {
        delete snip.editable
        await sniplets.salvaConfigurazione(snip)
      },
      numSnipGruppo(snip) {
        return this.snipletsEsistenti.filter(x => x.gruppo === snip.gruppo).length
      },
      moveUp(snip) {
        let sns = this.snipletsEsistentiGruppo(snip.gruppo)
        const idxSnip = sns.findIndex(x => x.codice === snip.codice)
        if (idxSnip > 0) {
          snip.ordine = idxSnip - 1
          sns[idxSnip - 1].ordine = idxSnip
        } else {
          snip.ordine = 0 // nel caso non fosse presente
        }
      },
      moveDown(snip) {
        let sns = this.snipletsEsistentiGruppo(snip.gruppo)
        const idxSnip = sns.findIndex(x => x.codice === snip.codice)
        const tot = this.numSnipGruppo(snip) - 1
        if (idxSnip < tot) {
          snip.ordine = idxSnip + 1
          sns[idxSnip + 1].ordine = idxSnip
        } else {
          snip.ordine = tot // nel caso non fosse presente
        }
      },
      onModificaGruppo(gruppo) {
        this.undoGruppo = { ...gruppo }
        gruppo.editable = true
      },
      async onAnnullaGruppo(gruppo) {
        if (this.undoGruppo) {
          let gruppoE = this.snipletsGruppi.find(x => x.codice === this.undoGruppo.codice)
          Object.assign(gruppoE, { ...this.undoGruppo})
          this.undoGruppo = null
        }
        gruppo.editable = false
      },
      async onSalvaGruppo(gruppo) {
        gruppo.editable = false
        await sniplets.salvaGruppo(_.omit(gruppo,'editable'))
      },
      onAddGruppo() {
        const tot = this.snipletsGruppi.length
        const newEl = {
          codice: uuidv4(),
          descrizione: 'Nuovo gruppo',
          note: '',
          ordine: tot,
          editable: true
        }
        this.snipletsGruppi.push(newEl)
      },
      onDeleteGruppo(gruppo) {
        this.titoloConferma = 'Cancellazione gruppo'
        this.messaggioConferma = `Confermi la cancellazione del gruppo ?`
        this.onConferma = this.deleteGruppo
        this.toDeleteGruppo = gruppo
        this.dialogConferma = true
      },      
      async deleteGruppo() {
        this.dialogConferma = false
        const gruppo = this.toDeleteGruppo
        const idx = this.snipletsGruppi.findIndex(x => x.codice === gruppo.codice)
        this.$delete(this.snipletsGruppi, idx)
        // cancellare anche su DB se esistente
        await sniplets.cancellaGruppo(gruppo)
        this.toDeleteGruppo = null
      },      
      onAddConfig(gruppo) {
        const tot = this.snipletsEsistenti.filter(x => x.gruppo === gruppo).length
        const newEl = {
          codice: uuidv4(),
          titolo: '',
          note: '',
          categoria: 'Impostazioni',
          gruppo,
          posizione: '',
          default: false,
          daPersonalizzare: false,
          payload: {},
          ordine: tot,
          editable: true
        }
        this.snipletsEsistenti.push(newEl)
      },
      onDeleteConfig(snip) {
        this.titoloConferma = 'Cancellazione impostazione'
        this.messaggioConferma = `Confermi la cancellazione dell\' impostazione ?`
        this.onConferma = this.deleteConfig
        this.toDelete = snip
        this.dialogConferma = true
      },      
      async deleteConfig() {
        this.dialogConferma = false
        const snip = this.toDelete
        const idx = this.snipletsEsistenti.findIndex(x => x.codice === snip.codice)
        this.$delete(this.snipletsEsistenti, idx)
        await sniplets.cancellaConfigurazione(snip)
        this.toDelete = null
      },
      onChangeDescrizioneGruppo(gruppo) {
        var pattern = /[^a-zA-Z0-9-_]+/gi
        gruppo.codice = gruppo.descrizione.toLowerCase().replace(pattern, '_')
      },
      async leggeSnipletsEsistenti() {
        const items = await sniplets.getSniplets()
        this.snipletsEsistenti = items.elenco
        const gruppi = items.gruppi.map(x => { return { ...x, editable: false}})
        this.snipletsGruppi = _.sortBy(gruppi, 'descrizione')     
      },
      onSalvaImpostazioni() {
        this.titoloConferma = 'Salvataggio configurazione'
        this.messaggioConferma = `Confermi il salvataggio della configurazione e l'aggiornamento dei database ?`
        this.onConferma = this.salvaImpostazioni
        this.dialogConferma = true
      },
      async salvaImpostazioni() {
        this.dialogConferma = false
/*         const selezionati = this.snipletsEsistenti.filter(x => x.selected).map(x => {
          return {
            codice: x.codice
            // verificare altri parametri
          }
        })
        this.snipletsCliente = await sniplets.salvaConfigurazioneCliente(this.cliente.codice, selezionati)
        await this.aggiornaDatabaseCliente()
        await this.aggiornaSniplets() */
      },
      totaleSelezionati(item) {
        const snGruppo = this.snipletsEsistenti.filter(x => x.gruppo === item.codice)
        const es = snGruppo.length
        const def = snGruppo.filter(x => x.default).length
        // const sel = snGruppo.filter(x => x.selected).length
        // return `${sel}/${es} (${def})`
        return `${es} (${def})`
      },      
      snipletsEsistentiGruppo(gruppo) {
        return _.sortBy(this.snipletsEsistenti.filter(x => x.gruppo === gruppo), 'ordine')
      },      
      operazioneEseguita(messaggio) {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = messaggio || 'Operazione eseguita con successo ! '
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.confirmText = 'SI'
        this.onlyOk = false
      }      
    }
  }
</script>

<style lang="scss">
   .nested-badge .v-badge--icon .v-badge__badge {
    padding: 0!important;
  }

  .jsoneditor-outer,  .ace_editor.ace-jsoneditor {
    min-height: 500px;
  }
  @media all and (max-height: 800px) {
    .jsoneditor-outer,  .ace_editor.ace-jsoneditor {
      min-height: 450px;
    }
  }
</style>
