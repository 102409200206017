<template>
  <v-row>
    <v-col xs="12" sx="12" md="12" xl="12">
      <v-card flat :loading="loading">
        <v-card-title>
          Elenco file nel repository
          <v-btn icon class="ml-3">
            <v-icon @click="getDirList">mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <file-dir-list :files="files" :moduli="moduli" @delete="onDeleteFile" @deleteFolder="onDeleteFolder" @refresh="getDirList" @save="onSaveFile" @createFolder="onCreateFolder"/>     
        </v-card-text>    
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid'
  import utility from '@/services/utilityService'
  import FileDirList from './fileDirList'

  export default {
    components: {
      FileDirList
    },
    data () {
      return {
        elencoFile: '',
        loading: false,
        files: [],
        moduli: [],
        filesInFolder: [],
        defaultDati: {
          codice: '',
          titolo: '',
          note: '',
          categoria: 'Generale',
          gruppo: 'Cassa',
          posizione: '',
          default: true,
          daPersonalizzare: true,
          tag: 'tutti',
          payload: {
          },
          meta: {
          }
        },
        defaultMd:'##'
      }
    },
    async mounted() {
      await this.getDirList()
      // await this.getModuleVersion()
    },
    methods: {
      onClearFile() {
        this.elencoFile = ''
      },
      async onChangeMultiple(files) {
        if (!files || files.length === 0) {
          return
        }
        this.loading = true
        const form_data = new FormData();
        for (let ff of files) {
          form_data.append('multiple_zip', ff, ff.name)
        }
        this.elencoFile = await utility.uploadRepositoryMultipleFile(form_data)
        await this.getDirList()
        // await this.getModuleVersion()
        this.loading = false
      },
      async getDirList() {
        try {
          this.files = await utility.getk8FolderFileList('configs')
        } catch {
        }
      },
      async onDeleteFile(item) {
        const pathFile = 'configs/' + item.dir + '/' + item.file
        await utility.deleteFile(pathFile)
        await this.getDirList()
      },
      async onDeleteFolder(folder) {
        const pathFolder = 'configs/' + folder
        await utility.deleteFolder(pathFolder)
        await this.$nextTick()
        await this.getDirList()
      },
      async onCreateFolder(folder) {
        const configFolder = 'configs/' + folder
        await utility.createFolder(configFolder)
        await this.getDirList()
      },
      async onSaveFile(folder, datiJson, datiMd) {
        let defaultDati = this.defaultDati
        defaultDati.codice = uuidv4()
        const files = [
          { nome: 'dati.json', dati: JSON.stringify(datiJson || defaultDati, null, 4) },
          { nome: 'info.md', dati: datiMd || this.defaultMd }
        ]
        const configFolder = 'configs/' + folder
        await utility.saveFile(configFolder, files)
        await this.getDirList()
      }      
/*       async getModuleVersion() {
        try {
          this.moduli = await utility.getModuleVersion()
        } catch {}
      } */
/*       async onDirSelected(pathId) {
        this.filesInFolder = this.files.filter(x => x.dir === pathId)
      } */
    }
  }
</script>
